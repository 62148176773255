<template>
    <div>
        <qy-top-nav title="收货地址" />
        <van-address-list v-model="chosenAddressId" :list="addressList" default-tag-text="默认" @add="onAdd" @edit="onEdit" @select="onCheck">
          
        </van-address-list>
        
    </div>
</template>
<script type="text/javascript">
import request from "@/utils/request";
export default {
    name: "Address",
    components: {},
    data() {
        return {
            chosenAddressId: null,
            addressList: null,
			userId:"",
            selectRow: null
        };

    },
    created() {
		this.userId=this.getUrlParam("userId","");
        this.refreshList()
    },
    computed: {

    },
    methods: {
        refreshList() {
            request.post("/api/consignee/list", {
				userId:this.userId
			}).then(rs => {
                if (rs.data) {
                    for (let i = 0, data = rs.data; i < data.length; i++) {
                        let consign = data[i];
                        consign.tel = consign.phone;
                        consign.isDefault = !!consign.isDefault;
                        if(consign.isDefault){
                            this.chosenAddressId=consign.id;
                        }
                        consign.address = consign.city + consign.county + " " + consign.address;
                    }
                }
                this.addressList = rs.data;

            })
        },
        onAdd() {
            this.$router.push({ path: '/address/add', query: {userId:this.userId}})
        },
        onEdit(row) {
            this.$router.push({ path: '/address/edit', query: { id: row.id } })
        },
        onCheck(row) {
            request.post("/api/consignee/edit", {
                id: row.id,
                isDefault: 1
            }).then(rs => {
                this.refreshList();
            })
        },
        onCheckB(row) {
            this.Dialog.confirm({ message: "确定要设为默认地址吗" }).then(() => {

            });

        }
    }
}
</script>
<style type="text/css" scoped="">
.address {
    display: flex;
    align-items: center;
}

.xing span {
    background: red;
    display: inline-block;
    border-radius: 17px;
    padding: 5px 7px;

}

.address>div {
    min-width: 3em;
    text-align: center;
}

.address .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.info p {
    margin: 8px 4px;
}

.address>div:nth-of-type(3) {}

.delBtn {
    float: right;
}
body{background:#f7f7f7;height:100%;}
.van-address-list{background:#f7f7f7;height:100%;padding-bottom:0px;}
.van-address-item{padding:10px;margin:5px 0px;border-radius:5px;}
.van-address-item .van-cell{padding:15px 0px;}
.van-address-list__bottom{bottom:20px;}

</style>